<template>
  <div class="donate-person-list">
    <div class="dpl-tool"></div>
    <div class="dpl-list">
      <van-pull-refresh v-model="isLoadingNew" @refresh="handleListRefresh">
        <donate-person-item
          v-for="(item, idx) in list"
          :key="idx"
          :name="item.name"
          :amount="item.amount"
          :time="item.time"
          :message="item.message"
          :college="item.college"
          :avatar="item.avatar"
        />
      </van-pull-refresh>
    </div>
    <div class="dpl-more">
      <div v-if="!over" style="text-align: center">
        <van-button
          @click="loadMore"
          type="primary"
          size="mini"
          color="#969799"
          round
          plain
          :disabled="isLoadingOld"
          >{{ isLoadingOld ? "加载中..." : "加载更多" }}</van-button
        >
      </div>
      <van-divider v-else>已加载全部{{showAll?null:"(仅显示本账号捐赠记录)"}}</van-divider>
    </div>
  </div>
</template>

<script>
import DonatePersonItem from "@/components/DonatePersonItem";
import { getDonateRecordPro } from "@/api/donate";

export default {
  props: {
    pid: Number,
    gid: {
      type: String,
      default() {
        return null;
      },
    },
  },
  components: { DonatePersonItem },
  data() {
    return {
      over: false,
      list: [],
      listLoading: true,
      isLoadMore: false,
      firstItem: null,
      lastItem: 0,
      isLoadingOld: true,
      isLoadingNew: false,
      showAll: true,
    };
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      this.loadOldData();
      /*
      getDonateRecord(this.pid, this.gid).then((response) => {
        if (this.listLoading) {
          this.list = [];
        }
        this.list.push(...response.list);
        this.listLoading = false;
        if (response.next === null) {
          this.over = true;
        }
      });
      */
    },
    loadMore() {
      this.loadOldData();
    },
    loadOldData() {
      this.isLoadingOld = true;
      getDonateRecordPro(this.pid, "old", this.lastItem, this.gid).then(
        (response) => {
          this.list.push(...response.list);
          this.lastItem = response.last;
          this.isLoadingOld = false;
          this.showAll = response.show_all;
          if (!response.next) {
            this.over = true;
          }
          if (!this.firstItem) {
            this.firstItem = response.first;
          }
        }
      );
    },
    loadNewData() {
      this.isLoadingNew = true;
      getDonateRecordPro(this.pid, "new", this.firstItem, this.gid).then(
        (response) => {
          if (response.count > 0) {
            let new_list = response.list.concat(this.list);
            this.list = new_list;
            this.firstItem = response.first;
            this.showAll = response.show_all;
          }
          this.isLoadingNew = false;
        }
      );
    },
    handleListRefresh() {
      this.loadNewData();
    },
  },
};
</script>

<style>
.dpl-more {
  margin-top: 1rem;
}
</style>
