<template>
  <div class="person-item">
    <van-row>
      <van-col span="3">
        <div class="person-item-avatar">
          <img v-lazy="avatar" alt="media" />
        </div>
      </van-col>
      <van-col span="20" offset="1">
        <div class="person-item-text">
          <!--
          <div class="person-item-title">
            <p class="person-item-amount">
              {{ name }}为该项目捐赠<span class="red-text">{{ amount }}</span
              >元<template v-if="college"
                >&nbsp;&nbsp;<van-tag plain type="primary">{{
                  college
                }}</van-tag></template
              >
            </p>
          </div-->
          <div class="person-info-line">
            <div class="person-item-name">
              <p>
                {{ name
                }}&nbsp;<template v-if="college"
                  >&nbsp;&nbsp;<van-tag plain type="primary">{{
                    college
                  }}</van-tag
                  >&nbsp;&nbsp;</template
                >&nbsp;捐赠<span class="red-text">{{ amount }}</span
                >元
              </p>
            </div>
            <div>
              <p class="person-item-time">{{ timeBeauty }}</p>
            </div>
          </div>
          <div class="person-message-line">
            <div class="person-item-message">
              <p v-if="message">“{{ message }}”</p>
            </div>
          </div>
        </div>
      </van-col>
    </van-row>
  </div>
</template>

<script>
import { getDateDiff } from "@/utils/time";
export default {
  data() {
    return {
      timeBeauty: null,
    };
  },
  props: {
    name: String,
    avatar: String,
    amount: Number,
    time: Number,
    message: String,
    college: {
      type: String,
      default() {
        return null;
      },
    },
  },
  mounted() {
    this.timeBeauty = getDateDiff(this.time);
  },
};
</script>

<style>
.person-item {
  min-height: 3rem;
  margin-bottom: 0.2rem;
  padding: 0.5rem 1rem;
  background-color: #ffffff;
}

.person-item p {
  margin: 0;
}

.person-item-avatar {
  margin: 0;
  padding: 0;
  width: 4rem;
  height: 3rem;
  padding: 0.5rem;
}

.person-item-avatar img {
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
}

.person-item-title {
  height: 2rem;
}

.person-item-title p {
  line-height: 2rem;
}

.person-item-amount {
  float: left;
  font-size: 0.9rem;
}

.person-item-time {
  float: right;
  font-size: 0.8rem;
  margin-right: 1rem !important;
}

.person-item-message p {
  font-size: 0.8rem;
  line-height: 1.5rem;
  text-indent: 1rem;
}

.person-item-name {
  float: left;
}

.person-item-time {
  float: right;
}

.person-info-line,
.person-message-line {
  width: 100%;
  min-height: 2rem;
}
</style>
