<template>
  <div class="page-container" v-if="group !== null">
    <div>
      <div
        class="group-image"
        :style="{ backgroundImage: `url(${bannerImage})` }"
      ></div>
      <div class="group-title">
        <div class="group-avatar">
          <img :src="group.avatar" alt="" />
        </div>
        <div class="group-text">
          <p class="group-text-title">{{ group.name }}</p>
          <van-row>
            <van-col span="12">
              <p class="group-text-info">
                <svg-icon icon-class="captain" /> 队长：{{ group.leader }}
              </p>
              <p class="group-text-info">
                <svg-icon icon-class="member" /> 成员：{{ group.member }}
              </p>
            </van-col>
            <van-col span="12">
              <p class="group-text-info">
                <svg-icon icon-class="aim" /> 目标：￥{{ group.aim }}
              </p>
              <p class="group-text-info">
                <svg-icon icon-class="heart" /> 已筹：￥{{ group.amount }}
              </p>
            </van-col>
          </van-row>
          <p class="group-message-text" v-if="group.message">
            “{{ group.message }}”
          </p>
        </div>
      </div>
      <div class="group-info">
        <van-progress
          :percentage="group.progress"
          stroke-width="8"
          color="red"
        />
      </div>
    </div>
    <div class="block-title">
      <div class="block-title-left">项目详情</div>
    </div>
    <div class="block-content">
      <project-info
        v-if="loaded"
        class="project-info-box"
        :title="group.project_title"
        :image="group.project_image"
        :pid="group.project_id"
        :amount="group.project_amount"
        :member="group.project_member"
        :group="gid"
        :inviter="inviter"
      />
    </div>
    <van-sticky>
      <div class="block-title">
        <div class="block-title-left">团队成员</div>
      </div>
    </van-sticky>
    <div class="member-list">
      <donate-person-list v-if="loaded" :pid="group.project_id" :gid="gid" />
      <div style="margin-bottom: 3.5rem"></div>
    </div>

    <div class="group-tool">
      <van-row>
        <van-col span="12">
          <van-button
            round
            type="info"
            style="width: 80%"
            color="#FF0000"
            @click="handleGroupShare"
            >邀请朋友</van-button
          >
        </van-col>
        <van-col span="12">
          <van-button
            round
            type="info"
            style="width: 80%"
            color="#FF0000"
            @click="handleJoinGroup"
            >我要参加</van-button
          >
        </van-col>
      </van-row>
    </div>
    <share-guide v-model="showShare" :title="`${group.name}捐赠团队`" />
    <div v-if="isAdmin" class="delete-group">
      <van-button
        round
        size="small"
        icon="edit"
        type="primary"
        plain
        :url="`/editgroup/${this.gid}`"
      />
      <van-button
        @click="handleDeleteGroup"
        round
        size="small"
        icon="delete-o"
        type="danger"
        plain
      />
    </div>
  </div>
</template>

<script>
import DonatePersonList from "@/components/DonatePersonList";
import ProjectInfo from "@/components/ProjectInfo";
import { getGroupInfo, deleteGroup } from "@/api/group";
import ShareGuide from "@/components/ShareGuide";
import wxSdk from "@/utils/wxsdk";
import { getStorage } from "@/utils/storage";
import { Dialog } from "vant";
import BannerImage from "@/assets/images/1_1.png";

export default {
  components: { DonatePersonList, ProjectInfo, ShareGuide },
  data() {
    return {
      gid: null,
      group: null,
      loaded: false,
      shareImg: null,
      showShare: false,
      isAdmin: false,
      bannerImage: BannerImage,
      joinPath: null,
      userId: null,
      inviter: null,
    };
  },
  created() {
    this.gid = this.$route.params.gid;
    this.fetchData();
  },
  mounted() {
    this.inviter = this.$route.query.inviter;
  },
  methods: {
    fetchData() {
      getGroupInfo(this.gid).then((response) => {
        this.group = response;
        this.isAdmin = response.is_admin;
        this.userId = response.user;
        document.title = response.name;
        this.loaded = true;
        let group_route = this.$router.resolve({
          name: "GroupDonate",
          params: { gid: this.gid },
          query: { inviter: this.userId },
        });
        let share_url =
          document.location.protocol +
          "//" +
          document.location.host +
          group_route.href;

        if (response.amount > 0) {
          wxSdk.share(
            `${response.name}团队为${response.project_title}项目捐赠${response.amount}元`,
            getStorage("name") + "邀请您加入捐赠团队",
            share_url
          );
        } else {
          wxSdk.share(
            `${response.name}团队正在为${response.project_title}项目捐赠`,
            getStorage("name") + "邀请您加入捐赠团队",
            share_url
          );
        }
      });
    },
    handleJoinGroup() {
      this.$router.push({
        name: "ProjectDetail",
        params: { pid: this.group.project_id, tab: "project" },
        query: { group: this.group.id, inviter: this.$route.query.inviter },
      });
    },
    handleGroupShare() {
      this.showShare = true;
    },
    handleDeleteGroup() {
      Dialog.confirm({
        title: "删除团队",
        message: "仅删除团队，成员的捐赠记录将保存。（此操作无法恢复）",
      })
        .then(() => {
          deleteGroup(this.gid).then(() => {
            //this.$router.push({ path: `/project/${response.project}/project` });
            //window.location.replace(`/project/${response.project}/project`);
            window.history.go(-1);
          });
        })
        .catch(() => {
          // on cancel
        });
    },
  },
};
</script>

<style>
.group-tool {
  width: 100%;
  position: fixed;
  bottom: 0;
  background-color: #ffffff;
}

.group-tool .van-col {
  text-align: center;
}

.group-image {
  height: 13rem;
  background-size: cover;
}

.group-title {
  height: 6rem;
  margin-top: -3rem;
}

.group-avatar {
  float: left;
  margin-left: 2rem;
}

.group-avatar img {
  width: 6rem;
  height: 6rem;
  border-radius: 50%;
  border: 0.2rem #ffffff solid;
}

.group-info {
  height: 1rem;
  background-color: #ffffff;
  margin-top: -3rem;
  padding: 4rem 1.5rem 0 1.5rem;
}

.group-text {
  height: 6rem;
  float: left;
  margin-left: 1rem;
  margin-top: 1.5rem;
  min-width: 50%;
  max-width: 15rem;
}

.group-text p {
  margin: 0;
}

.group-text-title {
  font-size: 1.1rem;
  font-weight: bold;
  line-height: 1.5rem;
  max-width: 15rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.group-text-info {
  font-size: 0.8rem;
  line-height: 1.2rem;
  margin: 0;
}

.delete-group {
  position: absolute;
  right: 1rem;
  top: 1rem;
}

.group-text-info {
  white-space: nowrap;
}

.group-message-text {
  font-size: 0.7rem;
  font-style: italic;
  max-width: 14rem;
  text-indent: 1rem;
}
</style>
